import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nb";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/sk";
import "moment/locale/sv";

// import exact bundle version to avoid issues with web (especially IE)
import format from "number-format.js/lib/format.es5";
import BigNumber from "bignumber.js";
import moment, { LongDateFormatKey } from "moment";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _set from "lodash/set";

import fieldDataTypes from "./fieldsDataTypes.json";
import { LocaleData } from "./types";

const isArrayPlaceholderRegex = /(?=.{1,200}$)(\[[^\s(!.)]+])+/;

export const fromCentsToUnit = (cents) => {
  BigNumber.config({ DECIMAL_PLACES: 2, ROUNDING_MODE: 2 });
  return new BigNumber(cents).div(100).toNumber();
};

export const fromUnitToCents = (value) => {
  BigNumber.config({ DECIMAL_PLACES: 2, ROUNDING_MODE: 2 });
  return new BigNumber(value).multipliedBy(100).toNumber();
};

export const calculateTimeDifferenceInSeconds = (startTime: Date, endTime: Date): number => {
  return (endTime.getTime() - startTime.getTime()) / 1000;
};

const isShortDateFormat = (dateFormat: string): boolean =>
  Boolean(dateFormat && dateFormat.toLowerCase().includes("l"));

const applyFormatAction = (action, placeholderName, data) => {
  const value = placeholderName ? _get(data, placeholderName) : data;
  const formattedValue = action(value);
  _set(data, placeholderName, formattedValue);
};

export default class Formatter {
  private dataFormatters: { date: (value: string) => string; currency: (amount: number) => string };

  // @ts-ignore
  constructor(private readonly locale: string, private readonly formats) {
    this.dataFormatters = {
      currency: this.formatCurrency,
      date: this.formatDate,
    };

    moment.locale(locale);
  }

  formatCurrency = (amount: number) => {
    return format(this.formats.currency, fromCentsToUnit(amount));
  };

  formatCurrencyUnit = (amount: number, showCurrencySign = true) => {
    const formattedUnitString = format(this.formats.currency, amount);
    return showCurrencySign ? formattedUnitString : formattedUnitString.replace(/[^,.\d]/g, "");
  };

  formatDate = (value: string | Date) => {
    return value && moment(value).format(this.formats.date);
  };

  centUnits = (value: string) => {
    return new BigNumber(value.replace(/\D/g, "").trim()).toNumber();
  };

  formatData = (placeholderNames: string[], serviceData) => {
    if (!placeholderNames || !placeholderNames.length) {
      return serviceData;
    }

    return placeholderNames.reduce((data, placeholderName) => {
      if (isArrayPlaceholderRegex.test(placeholderName)) {
        this.applyFormatActionToArray(placeholderName, data);
        return data;
      }

      const formatAction = this.getFormatAction(placeholderName);
      if (formatAction) {
        applyFormatAction(formatAction, placeholderName, data);
      }

      return data;
    }, _cloneDeep(serviceData));
  };

  applyFormatActionToArray(placeholderName: string, data) {
    const nameParts = placeholderName.split(".");
    const objectPropertyNameWithArray = nameParts.shift();
    const arrayPropertyName = ((nameParts && nameParts.shift()) || "").replace(/[\[\]]/g, "");
    const arrayPropertyAccessPath = `${objectPropertyNameWithArray}.${arrayPropertyName}`;

    const array = _get(data, arrayPropertyAccessPath);
    const propertyName = nameParts[nameParts.length - 1];

    if (array && array.length) {
      array.forEach((obj) => {
        const formatAction = this.getFormatAction(placeholderName);
        if (formatAction) {
          applyFormatAction(formatAction, propertyName, obj);
        }
      });
    }
  }

  getFormatAction(placeholderName: string) {
    const dataType = fieldDataTypes[placeholderName];
    if (!dataType) {
      return null;
    }

    if (!this.dataFormatters[dataType]) {
      throw new Error(`Data type "${dataType}" is not supported`);
    }

    return this.dataFormatters[dataType];
  }

  getLongDateFormat(): string {
    if (isShortDateFormat(this.formats.date)) {
      return moment.localeData(this.locale).longDateFormat(this.formats.date as LongDateFormatKey);
    }

    return this.formats.date;
  }

  getLocaleData(): LocaleData {
    const localeData = moment.localeData(this.locale);
    return {
      months: localeData.months(),
      weekdays: localeData.weekdaysShort(),
    };
  }
}
